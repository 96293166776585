import FakeReact from 'Utils/fake-react';

import Modal from 'FakeReactComponents/pc/common/Modal';
import SuccessModalContent from 'FakeReactComponents/pc/home/SuccessModalContent/SuccessModalContent';
import LoginModalContent from 'FakeReactComponents/pc/home/LoginModalContent/LoginModalContent';
import VipModalContent from 'FakeReactComponents/pc/home/VipModalContent';

var Component = FakeReact.Component;

/**
 * 对话框组件。有 showSuccessModal 和 showLoginModal 两种状态分别控制显示领取成功和需要登陆的窗口
 *
 * @class CouponModal
 * @extends {Component}
 */
class CouponModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // console.log(this.props)
        var successModalContent = new SuccessModalContent({ 
            handleClickClose: this.props.handleClickSuccessClose,
            handleClickUseBtn: this.props.handleClickUseBtn,
        });
        var successModal = new Modal({
            children: successModalContent, 
            show: this.props.showSuccessModal, 
            showHeader: false,
            handleClickClose: this.props.handleClickSuccessClose,
        });
        // var container = document.createElement('div');
        // container.appendChild(successModal.el);
        // console.log(successModal);
        // this.props.children = container;
        var loginModalContent = new LoginModalContent({ 
            handleClickClose: this.props.handleClickLoginClose,
            handleClickLogin: this.props.handleClickLogin,
        });
        var loginModal = new Modal({ 
            children: loginModalContent, 
            showHeader: true,
            show: this.props.showLoginModal,
            title: '登录提醒',
            style: {
                opacity: 0.4
            },
            handleClickClose: this.props.handleClickLoginClose
        });
        var vipModal = new Modal({
            children: new VipModalContent({
                handleClickClose: this.props.handleClickCloseVipModal,
            }),
            show: this.props.showVipModal,
            title: '系统提示',
            handleClickClose: this.props.handleClickCloseVipModal,
        });

        this.props.children = [successModal, loginModal, vipModal];

        return `<div>${this.childRoot}</div>`;
    }
}

export default CouponModal;