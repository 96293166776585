import 'PcCss/secure-college/front/home/index.scss';
import '~utilsCss/animate.css'; // 淡入淡出的轮播图效果

import 'Libs/mislider/css/mislider.css';
import 'Libs/mislider/css/mislider-skin-cameo.css';

import 'Libs/mislider/mislider.js';

// import './recommend';
// import './article'; // 资讯
// import './book'; // 名著介绍
// import './teacher'; // 明星讲师

$(function() {
  // banner
  var $slider = $('#slider');
  $slider.owlCarousel({
    singleItem: true,
    items: 1,
    loop: true,
    rewind: false,
    autoplay: true,
    autoplaySpeed: 600,
    navSpeed: 600,
    dotsSpeed: 600,
    touchDrag: false,
    mouseDrag: false,
    lazyLoad: true,
    animateOut: 'fadeOut',
  });
  $('#navigation').on('click', '[data-type]', function() {
    $slider.trigger($(this).attr('data-type') + '.owl.carousel', [600]);
  });

  // 密码工程师 教师头像列表
  var $cispSlider = $('#cispSlider .js-main');
  $cispSlider.owlCarousel({
    items: 1,
    loop: true,
    autoplay: true,
    autoplaySpeed: 600,
    dotsSpeed: 600,
    touchDrag: false,
    mouseDrag: false,
    autoplayHoverPause: true,
  });
  $('#cispSlider .js-nav').on('click', '[data-type]', function() {
    $cispSlider.trigger($(this).attr('data-type') + '.owl.carousel', [600]);
  });

  // 业内专家
  var $teacherSlider = $('#teacherSlider .js-main');
  (function() {
    var teacherNum = $('.smzx-teacher__item').length;
    if (teacherNum >= 4) {
      $teacherSlider.owlCarousel({
        items: 4,
        loop: true,
        // autoplay: true,
        autoplaySpeed: 600,
        dotsSpeed: 600,
        touchDrag: false,
        mouseDrag: false,
        autoplayHoverPause: true,
      });
    }
  })();
  $('#teacherSlider .js-nav').on('click', '[data-type]', function() {
    $teacherSlider.trigger($(this).attr('data-type') + '.owl.carousel', [600]);
  });

  // 相关图书
  $(".mis-stage").miSlider({
    stageHeight: 430,
    slidesOnStage: 5,
    slidePosition: 'center',
    slideStart: 'beg',
    slideScaling: 150,
    offsetV: -5,
    centerV: true,
    navButtonsOpacity: 1,
    slideWidth: 327,
    // pause: false
  });

  // 最近观看
  function initViewLog() {
    $.get('/secure/my-course/recently')
    .done((res) => {
      const data = res.data;
      if (res.code !== 200 || !data || data.length <= 0) return;

      let htmlStr = '';
      data.forEach((item, index) => {
        if (index > 1) return; // 只显示两个记录
        htmlStr += `<li><a href="/course/${item.courseId}" target="_blank">${item.courseTitle}</a></li>`;
      });

      const $bannerLoginPanel = $('#bannerLoginPanel');
      const $viewlog = $bannerLoginPanel.find('.js-viewlog');

      $bannerLoginPanel.find('.js-nocontent').hide();
      $viewlog.find('ul').html(htmlStr);
      $viewlog.show();
    })
    .fail();
  }

  // 返回顶部
  function initScrollTopEvent() {
    var scrollTop = window.innerHeight;
    var $toTop = $('#toTop');
    var $document = $(document);
    var isShow = false;
    if ($document.scrollTop() > scrollTop) {
      $toTop.fadeIn();
      isShow = true;
    }

    function toggleTips() {
      if ($document.scrollTop() > scrollTop) {
        if (!isShow) {
          $toTop.fadeIn();
          isShow = true;
        }
      } else {
        if (isShow) {
          $toTop.hide();
          isShow = false;
        }
      }
    }

    $(window).on('scroll', toggleTips);

    $toTop.on('click', function() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }

  initViewLog();
  initScrollTopEvent();
});