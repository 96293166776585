/**
 * 获取url中的参数列表，返回值是array类型的值, 存放各参数键值对
 * @param {String} url url字符串
 */
function getUrlQuery(url) {
    var reg = /[?&]([^=&#]+)=([^&#]*)/g;
    var matchArr = url && url.match(reg);
    var params = [];
    if (matchArr) {
        matchArr.forEach(function (str) {
            var kv = reg.exec(str);
            var param = {};
            if (kv) {
                param.key = kv[1];
                param.value = kv[2];
                params.push(param);
            }
        });
    }
    return params;
}

/**
 * 将字符串转化为dom元素，用一个div标签包裹并返回
 * @param {String} domStr dom元素的字符串
 */
function createDOMFromString(domStr) {
    const div = document.createElement('div');
    div.innerHTML = domStr;
    // console.log(domStr);
    // todo: 改善判定单一根节点的逻辑
    if (Object.prototype.toString.apply(div.childNodes[0]) !== '[object Text]') {
        return div.childNodes[0]
    } else {
        return div.childNodes[1]
    }
    // return div;
}

function isArr(arr) {
    return Object.prototype.toString.call(arr) === '[object Array]';
}

function isFunc(func) {
    return Object.prototype.toString.call(func) === '[object Function]';
}

/**
 * 将一个组件挂载到某个节点
 * @param {Object} component 要挂载的组件对象
 * @param {*} wrapper 组件挂载的根节点
 */
function mount(component, wrapper) {
    let children = component.props && component.props.children;
    try {
        // todo。不允许插入非元素类型值
        wrapper.appendChild(component._renderDOM());
        component._mountChild();

        component.onStateChange = function (oldEl, newEl) {
            wrapper.insertBefore(newEl, oldEl);
            component._mountChild();

            if (isFunc(component.componentDidUpdate)) {
                component.componentDidUpdate();
            }

            wrapper.removeChild(oldEl);

            if (isFunc(component.componentWillUnmount)) {
                component.componentWillUnmount();
            }

            if (isArr(children)) {
                for (let i = 0; i < children.length; i++) {
                    let childComponentDidMount = children[i].componentDidMount;
                    if (isFunc(childComponentDidMount)) {
                        // console.log('mount', children[i]);
                        childComponentDidMount();
                    }
                }
            } else {
                let childComponentDidMount = children.componentDidMount;
                if (isFunc(childComponentDidMount)) {
                    // console.log('mount', children);
                    childComponentDidMount();
                }
            }
        }

        // console.log('挂载前');
        if (isFunc(component.componentDidMount)) {
            component.componentDidMount();
        }
        // console.log('挂载后')
    } catch (e) {
        console.log('挂载出错');
        console.log(e);
    }
}

// function reMount(component, wrapper) {
//     wrapper.appendChild(component._renderDOM());
// }

/**
 * 将样式对象转换为字符串返回
 * @param {Object} styles 样式属性键值对
 */
function stringifyStyleObj(styles) {
    var styleStr = '';
    if (Object.prototype.toString.call(styles) === '[object Object]') {
        for (var key in styles) {
            if (styles[key]) {
                styleStr += `${key}: ${styles[key]}; `;
            }
        };
    }
    // console.log(styleStr);
    return styleStr || '';
}

class Component {
    constructor(props = {}) {
        this.props = props;
        this.props.children = props.children || '';
        this._FR = {
            childRootId: `fr-childroot-${this.genRandomString()}`,
        }
        this.childRoot = `<div id="${this._FR.childRootId}" ${this.props._FR ? `class="${this.props._FR.childRootClass}"` : ''}></div>`;
    }
    /**
     * 设置组件状态
     */
    setState(state) {
        const oldEl = this.el;
        // IE兼容。有必要的时候再考虑深浅拷贝的问题。
        if (!Object.assign) {
            Object.defineProperty(Object, "assign", {
                value: function (target) {
                    'use strict';
                    if (target == null) {
                        throw new TypeError('Cannot convert undefined or null to object');
                    }

                    var to = Object(target);

                    for (var index = 1; index < arguments.length; index++) {
                        var nextSource = arguments[index];

                        if (nextSource != null) {
                            for (var nextKey in nextSource) {
                                if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                    to[nextKey] = nextSource[nextKey];
                                }
                            }
                        }
                    }
                    return to;
                },
                writable: true,
                configurable: true
            });
        }

        this.state = Object.assign({}, this.state, state);
        this._renderDOM();
        if (this.onStateChange) {
            try {
                this.onStateChange(oldEl, this.el);
            } catch (e) {
                console.log('重挂载失败');
                console.log(e);
            }
        }
    }

    genRandomString() {
        return Math.random().toString(36).substr(2);
    }

    /**
     * 利用子组件自己实现的render方法生成dom元素并赋给el属性
     * 组件必须由至少一个节点包裹
     */
    _renderDOM() {
        if (this.componentWillMount) {
            this.componentWillMount();
        }

        try {
            this.el = createDOMFromString(this.render());
        } catch (e) {
            console.log('渲染出错');
            console.log(e);
        }

        // 如果子组件某些标签定义了事件处理
        if (this.eventHandler) {
            var payload = isFunc(this.eventHandler) ? this.eventHandler() : {};

            for (var selector in payload) {
                // console.log(payload[selector]);
                var targets = this.el.querySelectorAll(selector);
                var elClassName;  // 保存组件根元素的css类名
                var elClassArr;  // 保存组件根元素的css类名数组
                var selectorName = selector.slice(1);  // 选择器去掉类或者id选择器

                // 如果后代中不存在该选择器对应元素，判断是否为组件根元素
                if(!targets || targets.length === 0) {
                    elClassName = this.el && this.el.className;
                    elClassArr = elClassName && elClassName.split(' ');
                    if(elClassArr && elClassArr.indexOf(selectorName) !== -1) {
                        targets = [this.el];
                    }
                }

                // 如果在子代根据选择器查找到元素
                if (targets.length > 0) {
                    // 兼容ie。ie9 NodeList 没有forEach
                    if (window.NodeList && !NodeList.prototype.forEach) {
                        NodeList.prototype.forEach = function (callback, thisArg) {
                            thisArg = thisArg || window
                            for (var i = 0; i < this.length; i++) {
                                callback.call(thisArg, this[i], i, this)
                            }
                        }
                    }
                    targets.forEach((target) => {
                        if (isArr(payload[selector])) {
                            // 多个事件监听，数组类型
                            payload[selector].forEach(function (eventHandler) {
                                target.addEventListener(eventHandler.type, eventHandler.handler);
                            });
                        } else {
                            // 一个事件监听，对象类型
                            target.addEventListener(payload[selector].type, payload[selector].handler);
                        }
                    });
                }
            }
        }

        return this.el;
    }

    _mountChild() {
        let childRoot;

        if (this.el) {
            childRoot = this.el.querySelector(`#${this._FR.childRootId}`); // 用于挂载子组件
        }
        // console.log(childRoot);

        // 如果传入了子组件，那么挂载到组件中 .fake-react-child-root 类处。类似于挂载react的{this.props.children}
        if (childRoot && this.props.children) {
            if (isArr(this.props.children)) {
                for (var child in this.props.children) {
                    mount(this.props.children[child], childRoot);
                }
            } else {
                mount(this.props.children, childRoot);
            }
        }
    }
}

export default { mount, Component, stringifyStyleObj, getUrlQuery }