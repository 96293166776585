import FakeReact from 'Utils/fake-react';
import './LoginModalContent.scss';

var Component = FakeReact.Component;

class LoginModalContent extends Component {
    eventHandler() {
        var that = this;
        return {
            '.modal-login-btn': {
                type: 'click',
                handler: function() {
                    // console.log('点击关闭')
                    that.props.handleClickLogin();
                }
            }
        };
    }
    render() {
        return `
            <div class="modal-login-content">
                <p>登录后才能领取优惠券哦~</p>
                <button class="modal-login-btn">登录</button>
            </div>
        `;
    }
}

export default LoginModalContent;