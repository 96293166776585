import FakeReact from 'Utils/fake-react';
import './no-more-card.scss';

var Component = FakeReact.Component;

class NoMoreCard extends Component {
    render() {
        return `<li class="index-coupon-nomore-card">
            <div class="index-coupon-wrap">
                <p>更多优惠活动还在路上，敬请期待~</p>
            </div>
        </li>`;
    }
}

export default NoMoreCard;