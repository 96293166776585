import FakeReact from 'Utils/fake-react';
import $ from 'jquery';

import './index.scss';

var Component = FakeReact.Component;
var TO_USE = 'TO_USE';
var TO_RECEIVE = 'TO_RECEIVE';
var NO_LEFT = 'NO_LEFT';
var NOT_START = 'NOT_START';

class CouponCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routeUri: props.routeUri,
            receiveStatus: props.receiveStatus,

            couponId: props.couponId, // 优惠券id
            couponType: props.couponType, // 优惠方式, 满减/打折
            amount: props.amount, // 打折条件
            discount: props.discount, // 减免金额或者优惠折扣（0.1-9.9）
            // got: props.got, // 是否已领取
            // left: props.left, // 是否有剩余
            name: props.name, // 优惠劵名称
            useType: props.useType, // 用途类型
            note: props.note,
        };
    }

    eventHandler() {
        return {
            // 点击领取按钮容器区域
            '.index-coupon-toreceive-button-wrap': {
                type: 'click',
                handler: this.handleClickReceiveBtn.bind(this)
            },
            // 点击使用按钮容器区域
            '.index-coupon-touse-button-wrap': {
                type: 'click',
                handler: this.handleClickUseBtn.bind(this)
            },
            '.index-coupon-wrap-bg': [
                {
                    type: 'mouseenter',
                    handler: function (e) {
                        var $couponItem = $(e.target).parent();
                        $couponItem.addClass('index-coupon-hover-bg');
                        $couponItem.removeClass('index-coupon-normal-bg');
                    }
                },
                {
                    type: 'mouseleave',
                    handler: function (e) {
                        var $couponItem = $(e.target).parent();
                        $couponItem.addClass('index-coupon-normal-bg');
                        $couponItem.removeClass('index-coupon-hover-bg');
                    }
                }
            ],
        };
    }

    // 点击立即使用
    handleClickUseBtn() {
        if (this.props.useType === 'VIP') {
            let reg = /\/vip\/([0-9]{1,})/;
            let matchRs = reg.exec(this.props.routeUri);
            let packageId = matchRs ? matchRs[1] : '';
            this.props.handleBuyVip(packageId);
        } else {
            window.location.href = `${this.state.routeUri}`;
        }
    }

    // 点击领取
    handleClickReceiveBtn() {
        this.props.handleClickReceiveBtn({
            couponId: this.state.couponId,
            needToReFetch: false,
            useType: this.props.useType
        });
    }

    genBtn(receiveStatus) {
        var btnClass = 'index-coupon-status-btn';
        var btnWrapClass = 'index-coupon-button-wrap';
        var btn;
        if (receiveStatus === NO_LEFT) {
            return '';
        } else if (receiveStatus === NOT_START) {
            btn = '<span class="index-coupon-early-tips">未到使用时间</span>';
        } else if (receiveStatus === TO_USE) {
            btnClass += ' index-coupon-btn-to-use'
            btn = `<span class="${btnClass}">立即使用</span>`
            btnWrapClass += ' index-coupon-touse-button-wrap'
        } else {
            btnClass += ' index-coupon-btn-to-receive'
            btn = `<span class="${btnClass}">点击领取</span>`
            btnWrapClass += ' index-coupon-toreceive-button-wrap'
        }

        // var btnWrapClass = receiveStatus === TO_RECEIVE || receiveStatus === TO_USE ? 'index-coupon-touse-button-wrap' : '';

        return `
        <div class="index-coupon-button-wrap ${btnWrapClass}">
            ${btn}
        </div>`
    }

    genStamp(receiveStatus) {
        if (receiveStatus === TO_USE || receiveStatus === NOT_START) {
            return '<div class="has-coupon-stamp"></div>';
        } else if (receiveStatus === NO_LEFT) {
            return '<div class="no-coupon-stamp"></div>';
        } else {
            return '';
        }
    }

    render() {
        const receiveStatus = this.state.receiveStatus;
        let bgClass = receiveStatus === TO_RECEIVE || receiveStatus === TO_USE ? ' index-coupon-wrap-bg' : '';
        return `
        <li class="index-coupon-item ${this.state.receiveStatus === NO_LEFT ? 'index-coupon-noleft-bg' : 'index-coupon-normal-bg'}">
            <div class="index-coupon-wrap ${bgClass}">
                ${this.genStamp(this.state.receiveStatus)}
                <div class="index-coupon-info-wrap${this.state.receiveStatus === NO_LEFT ? ' index-coupon-info-wrap-noleft' : ''}">
                    <p>
                        ${this.state.couponType === 'CASH' ? '<span class="minus-sm">￥</span><span class="minus-md">' + this.state.discount + '</span>' : '<span class="minus-md">' + this.state.discount + '</span><span class="minus-sm">折</span>'}
                    </p>

                    <p>
                        ${this.state.note}
                    </p>

                    <p>
                        满${this.state.amount}可用
                    </p>
                </div>

                ${this.genBtn(this.state.receiveStatus)}
            </div>
        </li>
        `;
    }
}

export default CouponCard;