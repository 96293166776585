/*
 * @Author: longzijian 
 * @Date: 2019-01-26 01:04:16 
 * @Last Modified by: longzijian
 * @Last Modified time: 2019-02-22 16:24:04
 */


import FakeReact from 'Utils/fake-react';
import './Modal.scss';

const Component = FakeReact.Component;
const stringifyStyleObj = FakeReact.stringifyStyleObj;

class Modal extends Component {

    /**
     *Creates an instance of Modal.
     * @param {Object} props children属性传入子组件，showHeader(boolean) 确定是否显示对话框header, show 用于判断是否显示对话框
     * @memberof Modal
     */
    constructor(props) {
        super(props);
        if (typeof this.props.showHeader === 'undefined') {
            this.props.showHeader = true;
        }
    }

    // componentDidMount() {
    //     let modalEl;
    //     if(this.el) {
    //         modalEl = this.el.querySelector('.modal');
    //         console.log(modalEl);
    //         console.log(modalEl.offsetWidth);
    //     }
    // }

    eventHandler() {
        const that = this;
        return {
            '.modal-close-btn': {
                type: 'click',
                handler: function () {
                    that.props.handleClickClose();
                }
            },
        };
    }

    // 主要用于处理对话框宽度
    // todo，改善设置对话框宽度的逻辑
    modalStyle() {
        var styleStr = '';
        var modalWidth;
        if (this.props.style) {
            modalWidth = this.props.style.modalWidth;
            // console.log(modalWidth);
            if (Object.prototype.toString.call(modalWidth) === "[object Object]") {
                if (modalWidth.unit && modalWidth.number) {
                    styleStr = stringifyStyleObj({
                        width: `${modalWidth.number}${modalWidth.unit}`,
                        'margin-left': `-${modalWidth.number / 2}${modalWidth.unit}`
                    });
                }
            } else if (Object.prototype.toString.call(modalWidth) === "[object String]") {
                styleStr = stringifyStyleObj({ width: modalWidth });
            }
        }
        // console.log(styleStr);
        return styleStr;
    }

    render() {
        // fake-react-child-root节点用于挂载对话框内容
        return `
        <div class="modal-wrap" style="display: ${this.props.show ? 'block' : 'none'}">
            <div class="modal-bg" style="${stringifyStyleObj({ opacity: this.props.style && this.props.style.bgOpacity || '' })}"></div>

            <div class="modal" style="${this.modalStyle()}">
                <div class="modal-header" style="display: ${this.props.showHeader ? 'block' : 'none'}">
                    <p class="modal-title">${this.props.title || ''}</p>
                </div>

                <div class="modal-body">
                    ${this.childRoot}
                </div>

                <div 
                    class="modal-close-btn ${this.props.device === 'mobile' ? 'modal-close-btn-mobile' : 'modal-close-btn-pc'}"
                    style="display: ${this.props.hideCloseBtn ? 'none' : 'block'}"
                ></div>
            </div>
        </div>
        `;
    }
}

export default Modal;