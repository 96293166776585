import FakeReact from 'Utils/fake-react';
import './index.scss';

var Component = FakeReact.Component;

class VipModalContent extends Component {
    eventHandler() {
        var that = this;
        return {
            '.vip-modal-confirm-btn': {
                type: 'click',
                handler: function() {
                    // console.log('点击关闭')
                    that.props.handleClickClose();
                }
            },
        };
    }
    render() {
        return `
            <div class="vip-modal-content">
                <p>您已是会员，无需重复购买~</p>
                <button class="vip-modal-confirm-btn">好的</button>
            </div>
        `;
    }
}

export default VipModalContent;