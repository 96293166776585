import FakeReact from 'Utils/fake-react';
import './SuccessModalContent.scss';

var Component = FakeReact.Component;

class SuccessModalContent extends Component {
    eventHandler() {
        var that = this;
        return {
            '.success-close-btn': {
                type: 'click',
                handler: function() {
                    // console.log('点击关闭')
                    that.props.handleClickClose();
                }
            },
            '.success-use-btn': {
                type: 'click',
                handler: function() {
                    that.props.handleClickUseBtn();
                }
            }
        };
    }

    render() {
        return `
            <div class="getcoupon-success-modal-content">
                <div class="success-wrap">
                    <div class="success-ico"></div>
                </div>
                <p class="success-tips">恭喜您，领取成功</p>
                <div class="success-modal-btn-wrap">
                    <button class="success-use-btn">立即使用</button>
                    <button class="success-close-btn">关闭</button>
                </div>
            </div>
        `;
    }
}

export default SuccessModalContent;