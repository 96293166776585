import 'PcCss/front/home/index.scss';

import 'Utils/main-pc';
import 'FakeReactModules/pc/home/index-coupon/index-coupon.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import '../../secure-college/front/home/index';

// $(function() {
//   // banner
//   var $slider = $('#slider');
//   $slider.owlCarousel({
//     singleItem: true,
//     items: 1,
//     loop: true,
//     autoplay: true,
//     autoplaySpeed: 600,
//     navSpeed: 600,
//     dotsSpeed: 600,
//     touchDrag: false,
//     mouseDrag: false
//   });

//   $('#navigation').on('click', '[data-type]', function(event) {
//     $slider.trigger($(this).attr('data-type') + '.owl.carousel', [600]);
//   });
// });