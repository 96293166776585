import $ from 'jquery';
import 'core-js/fn/promise';
import 'babel-polyfill';

$.ajaxSetup({
  cache: false
});

$(function() {
  console.info(_VERSION);

  // 统计未读信息
  var $news = $(".js-news");
  if (typeof $news != "undefined") {
    var messageCount = 0;
    var notificationCount = 0;
    $.get("/secure/message/unread-count", function(json) {
      messageCount = json.data;
      $.get("/secure/message/notification/unread-count", function(json) {
        notificationCount = json.data;
        var allCount = messageCount + notificationCount;
        if (0 < allCount) {
          $news.addClass("js-news--new");
          $('.js-newCount').text(allCount);
        } else {
          $('.js-newCount').text(0);
        }
      }, "json");
    }, "json");
  }

  // 搜索关键词列表
  function initQuickSearchList() {
    $.get('/security/search-get')
    .done(function(res) {
      var data = res.data;
      if (res.code !== 200 || !data || data.length <= 0) return;
      var htmlStr = '';
      data.forEach(function(item) {
        htmlStr += '<span>' + item + '</span>';
      });
      var $searchShortcut = $('.js-searchShortcut');
      $searchShortcut.html(htmlStr);
    })
    .fail();
  }

  initQuickSearchList();

  // 点击关键字搜索课程
  $('#searchForm')
    .on('click', '.js-searchShortcut', function(e) {
      var keyWord = $(e.target).text();
      var $input = $('#searchForm').find('input[type=text]');
      var $submitBtn = $('#searchForm').find('input[type=submit]');
      console.log(123, keyWord, $input, $submitBtn);
      $input.val(keyWord);
      $submitBtn.click();
    })
    .on('input', 'input[type=text]', function(e) {
      var $target = $(e.target);
      $target.toggleClass('js-active', $.trim($target.val()) !== '');
    });
});