import FakeReact from 'Utils/fake-react';
import CouponCard from 'FakeReactComponents/pc/home/CouponCard/';
import NoMoreCard from 'FakeReactComponents/pc/home/CouponCard/NoMoreCard';
import $ from 'jquery';

import './index.scss';

let Component = FakeReact.Component;
 
class CouponList extends Component {
    constructor(props) {
        super(props);
    }

    totalPage() {
        let page = 1;
        if (this.props.list && this.props.list.length > 0) {
            page = Math.ceil((this.props.list.length + 1) / 4); // 加1是为了增加一个无更多的提示卡
        }
        page = page > 0 ? page : 1;

        return page;
    }

    eventHandler() {
        let that = this;
        let moving = false;  // 动画停止后才允许第二次点击

        return {
            // 点击上一页
            '.index-coupon-prev-btn': {
                type: 'click',
                handler: function() {
                    let $couponList = $('.index-coupon-list');
                    let originLeft = $couponList.css('left');
                    originLeft = parseInt(originLeft.replace('px', ''));
                    let positionLeft = `${originLeft + 1400}px`;
                    // console.log(originLeft, positionLeft)
                    // console.log('点击上一页', that.state.currPage, that.totalPage());

                    if (moving === false && that.props.currPage > 1) {
                        moving = true;
                        $couponList.animate({
                            left: positionLeft,
                        },
                        800,
                        function() {
                            moving = false;
                            that.props.handleListStateChange({ positionLeft, currPage: that.props.currPage - 1 });
                        });
                    }
                }
            },

            // 点击下一页
            '.index-coupon-next-btn': {
                type: 'click',
                handler: function() {
                    let $couponList = $('.index-coupon-list');
                    let positionLeft = `${that.props.currPage * 1400 * -1}px`;
                    // console.log('点击下一页', that.state.currPage, that.totalPage());
                    if (moving === false && that.props.currPage < that.totalPage()) {
                        moving = true;
                        $couponList.animate({
                            left: positionLeft
                        },
                        800,
                        function() {
                            moving = false;
                            that.props.handleListStateChange({ positionLeft, currPage: that.props.currPage + 1 });
                        });
                    }
                }
            }
        };
    }

    render() {
        let children = [];
        let noMoreCard = new NoMoreCard();
        if(this.props.list && this.props.list.length > 0 ) {
            this.props.list.forEach((coupon) => {
                let couponProps = Object.assign({}, coupon, { 
                    handleClickReceiveBtn: this.props.handleClickReceiveBtn,
                    handleClickVipRenew: this.props.handleClickVipRenew,
                    handleBuyVip: this.props.handleBuyVip,
                });
                let couponCard = new CouponCard(couponProps);
                children.push(couponCard);
            });
        }
        children.push(noMoreCard);
        this.props.children = children;
        // console.log(children);

        return `
        <div class="index-coupon-list-wrap">
            <div class="index-coupon-page-btn index-coupon-prev-btn"></div>
            <div class="index-coupon-page-btn index-coupon-next-btn"></div>

            <div class="index-coupon-list-mdwrap">
                <ul class="index-coupon-list" style="width: ${this.totalPage() * 1400}px; left: ${this.props.positionLeft}">
                    ${this.childRoot}
                </ul>
            </div>
        </div>
        `;
    }
}

export default CouponList;