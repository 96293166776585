import FakeReact from 'Utils/fake-react';
import './index.scss';
 
class Popup extends FakeReact.Component {
    render() {
        return `<div class="popup-wrap" style="display: ${this.props.show ? 'block' : 'none'}">
            <span class="popup">
            ${this.props.msg}
            </span>
        </div>`;
    }
}

export default Popup