import $ from 'jquery';

import FakeReact from 'Utils/fake-react';
import CouponModule from 'FakeReactModules/pc/home/index-coupon/CouponModule';

var mount = FakeReact.mount;

$(function () {
    // var $log = new Log("body");
    // var totalPage = 1; // 有多少页
    var couponsRoot = document.querySelector('.coupon-module-root');
    var couponModule = new CouponModule();
    if(couponsRoot) {
        mount(couponModule, couponsRoot);
    }
});