import FakeReact from 'Utils/fake-react';
import CouponList from 'FakeReactComponents/pc/home/CouponList';
import CouponModal from 'FakeReactComponents/pc/home/CouponModal';  // 对话框
import Popup from 'FakeReactComponents/pc/common/Popup';
import $ from 'jquery';

import './index.scss';

const Component = FakeReact.Component;
const TO_USE = 'TO_USE';
const NO_LEFT = 'NO_LEFT';
const NOT_START = 'NOT_START';

class Coupons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRenewVipModal: false, // 是否显示 vip 无需再次购买的对话框
            showSuccessModal: false,
            showLoginModal: false,
            popupMsg: '',
            showPopUp: false,
            popupTimer: null,
            currPage: 1,
            listPositionLeft: 0,  // 保存列表水平位置，因为领券、设置状态后会重新渲染，需要确定上次停留位置
            list: [],
            gotCoupon: null, // 刚领取到的券所拥有的数据
            gotCouponUri: '',  // 刚获取的优惠券使用uri，用于对话框中的按钮跳转
        }
    }

    componentDidMount() {
        // 若登陆前点击领券，获取完所有优惠券后再调用领券接口
        this.fetchCoupons({
            callback: () => {
                const beforeLoginCouponStr = window.localStorage.getItem('beforeLoginCoupon');  // 登陆前点击领券留在本地的优惠券id
                const beforeLoginCoupon = JSON.parse(beforeLoginCouponStr);

                if (beforeLoginCoupon) {
                    // 如果本地存有优惠券id，则调用接口进行领券
                    this.getCoupon({
                        couponId: beforeLoginCoupon.couponId,
                        isGotCouponBeforeLogin: true,
                        useType: beforeLoginCoupon.useType
                    });
                    window.localStorage.removeItem('beforeLoginCoupon');
                }
            }
        });
    }

    fetchCoupons(payload) {
        $.ajax({
            url: '/coupons',
            type: 'GET',
        }).then((res) => {
            let list = res.data.couponList;

            this.setState({
                list,
            });

            if(payload && payload.callback) {
                payload.callback();
            }
        });
    }

    // 领取成功对话框，关闭按钮
    handleClickSuccessClose() {
        this.setState({ showSuccessModal: false });
    }

    // 领取成功对话框，立即使用按钮
    // 这个对话框只有在领券成功后显示。
    // 领券成功后会在getCoupon相关方法中将返回券数据放到state.gotCoupon中。
    handleClickModalUseBtn() {
        const gotCoupon = this.state.gotCoupon;
        if (gotCoupon) {
            if (gotCoupon.useType === 'VIP') {
                let reg = /\/vip\/([0-9]{1,})/;
                let matchRs = reg.exec(gotCoupon.routeUri);
                let packageId = matchRs ? matchRs[1] : '';
                // 普通用户购买vip
                this.handleBuyVip(packageId);
            } else {
                // 购买课程、套餐和全部商品的优惠券，进行路由跳转
                window.location.href = `${gotCoupon.routeUri}`
            }
        }

        // 点击立即使用后关闭对话框
        this.setState({
            showSuccessModal: false,
        });
    }

    // 需要登陆对话框，关闭按钮
    handleClickLoginClose() {
        this.setState({ showLoginModal: false });
        window.localStorage.removeItem('beforeLoginCoupon');  // 应该是表明不希望登陆进行领券，取消登陆后读取本地数据进行领券
    }

    // 需要登陆对话框，点击登陆
    handleClickLogin() {
        window.location.href = '/login';
    }

    handleListStateChange(payload) {
        this.setState({
            listPositionLeft: payload.positionLeft,
            currPage: payload.currPage,
        })
    }

    // 处理领券
    getCoupon(payload) {
        let couponId = payload.couponId;
        let useType = payload.useType;
        let isGotCouponBeforeLogin = payload.isGotCouponBeforeLogin;

        let url = `/coupon/${couponId}`;
        let data = {
            couponId: couponId,
        };

        $.ajax({
            url: url,
            type: 'POST',
            data: data,
        }).then((res) => {
            let resStatus = res.status;

            switch (resStatus) {
                case 'success': {
                    // 领取成功, 区分是否生效、是否是vip
                    this.handleGetCouponSuccess(couponId, isGotCouponBeforeLogin, useType, res);
                    break;
                }
                case 'fail': {
                    // 领取失败
                    this.handleGetCouponFailOrError({ couponId, useType, res, isGotCouponBeforeLogin });
                    break;
                }
                case 'error': {
                    // 领取错误
                    this.handleGetCouponFailOrError({ couponId, useType, res, isGotCouponBeforeLogin });
                    break;
                }
                default: {
                    this.handleGetCouponOtherErr();
                    break;
                }
            }
        }).fail((err) => {
            this.setState({
                showPopUp: true,
                popupMsg: `网络错误`,
                popupTimer: this.getPopupTimer(),
            });
        });
    }

    // 处理领券200返回状态
    handleGetCouponSuccess(couponId, isGotCouponBeforeLogin, useType, res) {
        const list = this.state.list;
        const couponData = res.data || {};
        const today = new Date();
        // res.data 是键值对
        const resStartDateStr = couponData.startDate;
        const spPos = resStartDateStr && resStartDateStr.indexOf(' ');  // 返回日期空格位置
        let startDateStr = resStartDateStr && resStartDateStr.substr(0, spPos);  // 返回日期去除时间
        startDateStr = startDateStr.replace(/\-/g, '/');  // 兼容IE/safari等，不能通过 '2019-01-01 00:00:00' 创建时间的浏览器
        const startDate = new Date(startDateStr + ' 00:00:00');  // 返回日期改为当天零点

        // console.log(startDate, today);
        // 未开始生效
        if (today < startDate) {
            list.forEach((coupon, index) => {
                if (coupon.couponId === couponId) {
                    list[index].receiveStatus = NOT_START;
                }
            });
            const startDateStr = couponData.startDate && couponData.startDate.substr(0, 10);
            const endDateStr = couponData.endDate && couponData.endDate.substr(0, 10);

            this.setState({
                // showSuccessModal: true,
                showPopUp: true,
                popupMsg: `领取成功! ${startDateStr} 至 ${endDateStr} 可使用~!`,
                popupTimer: this.getPopupTimer(),
                list,
                gotCoupon: Object.assign({}, couponData, { useType }),
                // gotCouponUri: routeUri,
            });
        } else {
            // 开始生效
            list.forEach((coupon, index) => {
                if (coupon.couponId === couponId) {
                    list[index].receiveStatus = TO_USE;
                    list[index].routeUri = couponData.routeUri;
                }
            });
            this.setState({
                showSuccessModal: true,
                list,
                gotCoupon: Object.assign({}, couponData, { useType }),
                gotCouponUri: couponData.routeUri,
            });
        }
    }

    // 处理领券失败或错误
    handleGetCouponFailOrError(payload) {
        let couponId = payload.couponId;
        let useType = payload.useType;
        let res = payload.res;
        let isGotCouponBeforeLogin = payload.isGotCouponBeforeLogin;

        let resCode = parseInt(res.code);
        let list = this.state.list;
        // console.log(resCode);
        switch (resCode) {
            // 需要登陆
            case 10001: {
                // 保存到本地等登陆后用于领券
                window.localStorage.setItem('beforeLoginCoupon', JSON.stringify({ couponId: couponId, useType: useType }));
                if (!isGotCouponBeforeLogin) {
                    // 如果不是登陆前点击领取按钮然后进入首页静默调用此方法，则表明是手动点击按钮调用此方法，应显示提示框
                    this.setState({
                        showLoginModal: true,
                    });
                }
                break;
            }
            // 已领光
            case 20001: {
                // 已抢光
                // 修改本地列表数据，将该券改为已抢光
                list.forEach((coupon, index) => {
                    if (coupon.couponId === couponId) {
                        list[index].receiveStatus = NO_LEFT;
                    }
                });
                this.setState({
                    list,
                    showPopUp: true,
                    popupMsg: '你来晚了，优惠券被抢光了~',
                    popupTimer: this.getPopupTimer()
                });
                break;
            }
            // 券过期, 刷新页面
            case 20002: {
                // 券过期，可能是首页停留时间超过了优惠券到期时间，页面上还显示旧数据
                window.location.reload();
                break;
            }
            case 20003: {
                // 已领取过
                this.setState({
                    showPopUp: true,
                    popupMsg: '你已经领取过啦~',
                    popupTimer: this.getPopupTimer()
                });
                break;
            }
            case 20004: {
                // 该券已在后台被删除
                // 重新调接口获取数据
                this.setState({
                    showPopUp: true,
                    popupMsg: '领取失败',
                    popupTimer: this.getPopupTimer(() => {
                        this.fetchCoupons();
                    })
                });
                // 重新取数据
                this.fetchCoupons();
                break;
            }
            // 其他失败原因
            default: {
                console.log(res)
                // msgLog.showFailLog(res.message, true, 2000);
                this.setState({
                    showPopUp: true,
                    popupMsg: '领券失败',
                    popupTimer: this.getPopupTimer()
                })
            }
        }
    }

    // 处理领券其他错误
    handleGetCouponOtherErr(res) {
        this.setState({
            showPopUp: true,
            popupMsg: '领取失败',
            popupTimer: this.getPopupTimer(),
        });
    }

    getPopupTimer() {
        this.setState({
            popupTimer: setTimeout(() => {
                this.setState({
                    showPopUp: false,
                })
            }, 3000),
        })
    }

    // 关闭已是会员对话框
    handleClickCloseVipModal() {
        this.setState({
            showRenewVipModal: false,
        })
    }

    // vip 用户，使用vip券弹出提示
    handleClickVipRenew() {
        this.setState({
            showRenewVipModal: true,
        });
    }

    // 处理点击vip优惠券立即使用
    handleBuyVip(vipPackageId) {
        const vipDiv = document.querySelector('.index-coupon-module-root-v');
        if (vipDiv) {
            // 已是vip
            this.setState({
                showRenewVipModal: true,
            });
            return;
        } else {
            this.setState({
                showSuccessModal: false,
            });
            let vipPackageStr = vipPackageId ? `&vipPackageId=${vipPackageId}` : '';
            let purchaseVipUri = `/secure/purchase?payType=vipPackage${vipPackageStr}`;
            // console.log(purchaseVipUri);
            $('#purchaseModal').load(purchaseVipUri);
        }
    }

    render() {
        let children = []
        let couponList = new CouponList({
            handleClickReceiveBtn: this.getCoupon.bind(this),
            handleListStateChange: this.handleListStateChange.bind(this),
            currPage: this.state.currPage,
            list: this.state.list,
            positionLeft: this.state.listPositionLeft,
            handleClickVipRenew: this.handleClickVipRenew.bind(this),
            handleBuyVip: (vipPackageId) => this.handleBuyVip(vipPackageId),
        });
        let couponModal = new CouponModal({
            showVipModal: this.state.showRenewVipModal,
            showSuccessModal: this.state.showSuccessModal,
            showLoginModal: this.state.showLoginModal,
            handleClickSuccessClose: this.handleClickSuccessClose.bind(this),
            handleClickUseBtn: this.handleClickModalUseBtn.bind(this),
            handleClickLoginClose: this.handleClickLoginClose.bind(this),
            handleClickLogin: this.handleClickLogin.bind(this),
            handleClickCloseVipModal: this.handleClickCloseVipModal.bind(this),
            handleBuyVip: () => this.handleBuyVip(),
        });
        let popUp = new Popup({
            show: this.state.showPopUp,
            msg: this.state.popupMsg,
        });
        children.push(couponList);
        children.push(couponModal);
        children.push(popUp);
        this.props.children = children;

        const moduleTitleEl = document.querySelector('#home-module-coupon-title');
        let moduleTitle;
        if (moduleTitleEl && moduleTitleEl.innerText) {
            moduleTitle = moduleTitleEl.innerText;
        } else {
            moduleTitle = '优惠活动';
        }

        return `
        <div class="coupon-container">
            <h2 class="index-coupon-title-wrap">
                <p class="index-coupon-title">${moduleTitle}</p>
            </h2> 

            <span class="index-my-coupon"><a href="/secure/coupon-manage/coupon-list">我的卡券包</a></span>
            ${this.childRoot}
        </div>
        `;
    }
}

export default Coupons;